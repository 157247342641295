import Cookies from 'js-cookie'
import tingle from 'tingle.js'
import { on, fire } from 'delegated-events'
import ajax from '@common/ajax'

const fn = {
  init: () => {
    on('click', '[data-modal]', fn.handleOpen)

    const autoOpenModal = document.querySelector('[data-auto-modal]')

    if (autoOpenModal) {
      fn.openModal(autoOpenModal)
    }
  },

  handleOpen: (e) => {
    if (e.target.tagName !== 'A' || (e.target.tagName === 'A' && e.target.dataset.modal)) {
      e.preventDefault()
      fn.openModal(e.target.closest('[data-modal]'))
    }
  },

  openModal: (el) => {
    let modal = null

    // Setup an image modal
    if (el.dataset.modal === 'image') {
      let opts = {
        cssClass: ['modal--image'],
      }

      modal = fn.buildImageModal(el, el.getAttribute('href'), opts)
    }

    // Setup a video modal
    if (el.dataset.modal === 'video') {
      let opts = {
        cssClass: ['modal--video'],
        closeMethods: ['button', 'escape'],
      }

      const href = el.getAttribute('href')

      if (href.includes('youtube')) {
        opts.cssClass.push('is-youtube')
      }

      modal = fn.buildVideoModal(el, href, opts)
    }

    // Setup a content/AJAX modal
    if (el.dataset.modal === 'content') {
      let opts = {
        cssClass: ['modal--content', 'modal--ajax'],
      }

      const href = el.dataset.href ? el.dataset.href : el.getAttribute('href')
      modal = fn.buildContentModal(el, href, opts)
    }

    // Setup an embed modal
    if (el.dataset.modal === 'embed') {
      let opts = {
        cssClass: ['modal--embed'],
        closeMethods: ['button'],
      }

      modal = fn.buildEmbedModal(el, el.getAttribute('href'), opts)
    }

    // Setup an auto HTML modal
    if (el.dataset.autoModal !== undefined) {
      if (el.dataset.autoModal === 'setcookie') {
        Cookies.set('seenautomodal', true, {
          expires: 1,
        })
      }

      let opts = {
        cssClass: ['modal--content'],
      }

      modal = fn.buildModal(el, opts)
      modal.setContent(el.innerHTML)
    }

    if (modal) {
      modal.open()
    }
  },

  buildModal: (el, opts = {}) => {
    let cssClass = ['modal']
    let closeMethods = ['button', 'escape', 'overlay']

    if (Object.prototype.hasOwnProperty.call(opts, 'cssClass')) {
      cssClass = cssClass.concat(opts.cssClass)
    }

    if (Object.prototype.hasOwnProperty.call(opts, 'closeMethods')) {
      closeMethods = opts.closeMethods
    }

    const modal = new tingle.modal({
      closeMethods,
      cssClass,
      beforeOpen: () => {
        modal.modal.classList.add('modal--loading')

        // Move the close button
        const closeBtn = modal.modal.querySelector('.tingle-modal__close')

        if (document.body.classList.contains('subsite-elevate')) {
          closeBtn.querySelector('.tingle-modal__closeIcon').innerHTML = `
            <span class="font-medium">Close</span>
            <svg viewBox="0 0 24 24">
              <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
            </svg>
          `
        } else {
          closeBtn.querySelector('.tingle-modal__closeIcon').innerHTML = `
            <svg viewBox="0 0 32 32">
              <path d="M30.783 1.218 1.218 30.782" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="m1.218 1.218 29.565 29.564" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          `
        }

        modal.modal.querySelector('.tingle-modal-box').appendChild(closeBtn)
      },
      onOpen: () => {
        modal.modal.classList.remove('modal--loading')
      },
      beforeClose: () => {
        fire(document.documentElement, 'modalclose', { modal: modal })

        return true
      },
      onClose: () => {
        modal.destroy()

        if (window._currentModal) {
          window._currentModal = null
        }
      },
    })

    return modal
  },

  buildImageModal: (el, href, opts = {}) => {
    const modal = fn.buildModal(el, opts)
    modal.setContent(`<img src="${href}">`)

    return modal
  },

  buildVideoModal: (el, href, opts = {}) => {
    const modal = fn.buildModal(el, opts)

    if (href.includes('youtube')) {
      modal.setContent(`<iframe style="border: none" src="${href}?autoplay=1&rel=0" allowfullscreen></iframe>`)
    } else {
      modal.setContent(`<video src="${href}" autoplay controls />`)
    }

    return modal
  },

  buildEmbedModal: (el, href, opts = {}) => {
    const modal = fn.buildModal(el, opts)
    modal.setContent(`<iframe style="border: none" src="${href}"></iframe>`)

    return modal
  },

  buildContentModal: (el, href, opts = {}) => {
    const modal = fn.buildModal(el, opts)

    ajax
      .url(href)
      .get()
      .text((resp) => {
        modal.setContent(resp)
        modal.modal.classList.remove('modal--ajax')
      })

    return modal
  },
}

export const openUrlModal = (url) => {
  let opts = {
    cssClass: ['modal--content', 'modal--ajax'],
  }

  const modal = fn.buildContentModal(null, url, opts)

  if (modal) {
    modal.open()
  }
}

export default {
  can: () => true,
  run: fn.init,
}
