import Cookies from 'js-cookie'
import { openUrlModal } from '@components/modal'

const overlayUrl = document.body.dataset.pageOverlayUrl
const overlayCookie = document.body.dataset.pageOverlayCookie

export default {
  can: () => overlayUrl !== undefined,
  run: () => {
    openUrlModal(overlayUrl)

    if (parseInt(overlayCookie) === 1) {
      Cookies.set('seenpageoverlay', true, { expires: 1 })
    }
  },
}
