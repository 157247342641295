import { on } from 'delegated-events'

const triggers = document.querySelectorAll('.sport-leagues-trigger')

const openMenu = async (trigger) => {
  Array.from(triggers)
    .filter((x) => x !== trigger)
    .forEach((x) => closeMenu(x))

  const target = document.getElementById(trigger.getAttribute('aria-controls'))
  target.classList.add('is-active')
  trigger.setAttribute('aria-expanded', 'true')
}

const closeMenu = async (trigger) => {
  const target = document.getElementById(trigger.getAttribute('aria-controls'))
  target.classList.remove('is-active')
  trigger.setAttribute('aria-expanded', 'false')
}

const handleTrigger = (e) => {
  const trigger = e.target.closest('.sport-leagues-trigger')

  if (trigger.getAttribute('aria-expanded') === 'false') {
    openMenu(trigger)
  } else if (trigger.getAttribute('aria-expanded') === 'true') {
    closeMenu(trigger)
  }
}

export default {
  can: () => document.body.classList.contains('pagetype-sportspage'),
  run: () => {
    on('click', '.sport-leagues-trigger', handleTrigger)

    window.addEventListener('click', (e) => {
      if (!e.target.closest('.sport-leagues-trigger') && !e.target.closest('.sport-leagues-menu')) {
        triggers.forEach((trigger) => {
          closeMenu(trigger)
        })
      }
    })
  },
}
