import { on } from 'delegated-events'
import { Datepicker } from 'vanillajs-datepicker'
import { handleValidation, handleBlur } from '@common/validation'
import ajax from '@common/ajax'
import scrollTo from '@common/scroll-to'
import { trackGoogleAnalytics } from '@common/util'

const fn = {
  init: () => {
    on('blur', '.has-error', handleBlur, { capture: true })
    on('change', '.has-error', handleBlur, { capture: true })
    on('change', '.has-error blur', handleBlur, {
      capture: true,
    })
    on('submit', '[data-form-validate]:not(.multistepform)', (e) => handleValidation(e.target))
    on('submit', '[data-form-ajax]', fn.handleAjax)

    fn.setupSpecialFields()
  },

  handleAjax: (e) => {
    e.preventDefault()

    const $form = e.target
    const isValid = handleValidation($form)

    if (isValid) {
      const $formMessages = $form.querySelector('.form-messages')
      const $submitButton = $form.querySelector('[type=submit]')
      let autoHideMessage = true
      fn.toggleSubmit($submitButton)

      const formData = new FormData($form)

      ajax
        .url($form.action)
        .body(formData)
        .post()
        .json((res) => {
          if (res.success) {
            if (res.ga) {
              res.ga.forEach((event) => trackGoogleAnalytics(event))
            }

            $form.reset()
            $form.querySelectorAll('select').forEach((x) => (x.value = null))
            $form.querySelectorAll('.file-input-placeholder').forEach((x) => x.classList.add('is-hidden'))
            $form.querySelectorAll('.toggle-fields').forEach((x) => x.classList.add('hidden'))
            $form.querySelectorAll('.toggle-group').forEach((x) => x.classList.add('hidden'))
            $form.querySelectorAll('textarea.has-length-limit').forEach((el) => {
              fn.handleLengthLimit({ target: el })
            })

            if ($form.dataset.hideOnSubmit) {
              scrollTo($form)
              $form.querySelectorAll('.form-content').forEach((x) => x.classList.add('hidden'))
              autoHideMessage = false
            }
          }

          if (res.message) {
            fn.showFormMessages($formMessages, res.message, res.success, autoHideMessage)
          }
        })
        .catch(() => {
          fn.showFormMessages($formMessages, 'Sorry, there was a problem with your submission')
        })
        .finally(() => {
          fn.toggleSubmit($submitButton)
        })
    }
  },

  toggleSubmit: ($submitButton) => {
    $submitButton.disabled = !$submitButton.disabled
    $submitButton.classList.toggle('btn-loading')
  },

  showFormMessages: ($holder, message, success, autoHide) => {
    $holder.style.display = 'block'
    $holder.innerHTML = message

    if (success) {
      $holder.classList.remove('form-message-error')
    } else {
      $holder.classList.add('form-message-error')
    }

    if (autoHide) {
      setTimeout(() => {
        $holder.style.display = 'none'
      }, 5000)
    }
  },

  setupSpecialFields: () => {
    document.querySelectorAll('input.date-field').forEach((el) => {
      const minDate = new Date()
      minDate.setDate(minDate.getDate() + 1)

      new Datepicker(el, {
        autohide: true,
        clearButton: true,
        minDate: minDate,
        orientation: 'auto bottom',
      })
    })
  },
}

export default {
  can: () => true,
  run: fn.init,
}
