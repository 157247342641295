import { on } from 'delegated-events'
import scrollTo from '@common/scroll-to'

const fn = {
  init: () => {
    fn.bindEvents()
    fn.handleUrl()
    fn.handleScrollToMe()
  },

  bindEvents: () => {
    on('click', '[data-scroll]', fn.handleClick)
  },

  handleClick: (e) => {
    const el = e.target.closest('[data-scroll]')

    let targetSelector = null

    if (el.dataset.scroll) {
      targetSelector = el.dataset.scroll
    } else if (el.tagName === 'A') {
      targetSelector = el.hash
    } else {
      return
    }

    if (targetSelector) {
      const target = document.querySelector(targetSelector)

      if (target) {
        e.preventDefault()

        document.documentElement.classList.remove('js-menu-active')

        if (window._currentModal && window._currentModal.modal) {
          window._currentModal.close()
        }

        scrollTo(target)
      }
    }
  },

  handleUrl: () => {
    if (window.location.hash) {
      let targetSelector = window.location.hash

      let target = document.querySelector(targetSelector)

      if (target) {
        scrollTo(target)
      }
    }
  },

  // Automatically scrolls to the first element with the [data-scrolltome] attribute
  handleScrollToMe: () => {
    const target = document.querySelector('[data-scrolltome]')

    if (target) {
      setTimeout(() => {
        scrollTo(target)
      }, 250)
    }
  },
}

export default {
  can: () => true,
  run: fn.init,
}
