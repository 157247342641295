import { on, fire } from 'delegated-events'
import scrollTo from '@common/scroll-to'
import ajax from '@common/ajax'
import { createUrl } from '@common/util'
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper'

Swiper.use([Navigation, Pagination, EffectFade])

const resetForm = (form) => {
  form.querySelectorAll('input').forEach((input) => (input.value = null))
  form.querySelectorAll('select').forEach((select) => (select.selectedIndex = -1))
}

const handleClear = (e) => {
  e.preventDefault()

  const filters = e.target.closest('[data-page-filters]')

  filters.querySelectorAll('form').forEach((form) => resetForm(form))

  window.history.replaceState({}, '', e.target.href)

  fire(document.documentElement, 'pagefilters:update', { filtersEl: filters, url: window.location.href, type: 'clear' })

  filters.classList.remove('is-filtered')
}

const handleFormSubmit = (e) => {
  const form = e.target.closest('form')
  const filters = form.closest('[data-page-filters]')

  // Only one of the filters is active at a time, so clear all the other ones
  if (filters.dataset.pageFilters === 'single') {
    Array.from(filters.querySelectorAll('form'))
      .filter((x) => x !== form)
      .forEach((form) => resetForm(form))
  }

  // Fully replace the url with the given value
  if (form.dataset.submit && form.dataset.submit === 'replace') {
    e.preventDefault()

    const field = form[0]

    if (field) {
      window.history.replaceState({}, '', field.value)

      fire(document.documentElement, 'pagefilters:update', {
        filtersEl: filters,
        url: window.location.href,
        type: 'replace',
      })
    }
  }

  // Fully replace the url with the given value, but as a query param
  if (form.dataset.submit && form.dataset.submit === 'get') {
    e.preventDefault()

    const data = new FormData(form)
    const url = createUrl(form.action, data)

    window.history.replaceState({}, '', url)

    fire(document.documentElement, 'pagefilters:update', { filtersEl: filters, url: window.location.href, type: 'get' })
  }

  filters.classList.add('is-filtered')
  scrollTo(filters, 48)
}

const handleDropdownChange = (e) => {
  const parentForm = e.target.closest('form')

  if (parentForm) {
    if (parentForm.requestSubmit) {
      parentForm.requestSubmit()
    } else {
      parentForm.dispatchEvent(new Event('submit'))
    }
  }
}

const handleUpdate = async (e) => {
  const filtersEl = e.detail.filtersEl

  if (filtersEl.dataset.pageFiltersUpdate === 'replace') {
    const pageContent = document.querySelector('[data-page-content]')

    if (pageContent) {
      pageContent.classList.add('is-loading')

      const res = await ajax.get(e.detail.url).text()

      pageContent.innerHTML = res
      pageContent.classList.remove('is-loading')

      fire(document.documentElement, 'pagecontent:replace')
    }

    if (filtersEl.dataset.pageFiltersRefetch !== undefined) {
      const queryType = e.detail.url.indexOf('?') === -1 ? '?' : '&'
      const res = await ajax.get(`${e.detail.url}${queryType}fetchPageFilters=1`).text()

      const filtersContent = filtersEl.querySelector('#page-filters')

      if (filtersContent) {
        filtersContent.innerHTML = res

        setup(true)
      }
    }

    e.stopPropagation()
  }
}

const setupFeaturedPostSlider = () => {
  const featuredPostSlider = document.querySelector('.featured-post-slider')

  if (!featuredPostSlider) {
    return
  }

  const mainContainer = featuredPostSlider.querySelector('.featured-post-slider-main .swiper')
  const imageContainer = featuredPostSlider.querySelector('.featured-post-slider-image .swiper')
  const numSlides = mainContainer.querySelectorAll('.swiper-slide').length

  if (numSlides > 1) {
    const imageSwiper = new Swiper(imageContainer, {
      slidesPerView: 1,
      allowTouchMove: false,
      loop: true,
      loopedSlides: numSlides,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    })

    new Swiper(mainContainer, {
      slidesPerView: 1,
      loop: true,
      loopedSlides: numSlides,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: featuredPostSlider.querySelector('.slider-control-next'),
        prevEl: featuredPostSlider.querySelector('.slider-control-prev'),
      },
      pagination: {
        el: featuredPostSlider.querySelector('.slider-pagination'),
        clickable: true,
      },
      on: {
        slideChangeTransitionStart: (swiper) => {
          imageSwiper.slideTo(swiper.realIndex)
        },
      },
    })
  }
}

export default {
  can: () => document.body.classList.contains('pagetype-blogpage'),
  run: () => {
    on('change', '[data-page-filters] select', handleDropdownChange)
    on('submit', '[data-page-filters] form', handleFormSubmit, { capture: true })
    on('click', '[data-page-filters-clear]', handleClear)
    on('pagefilters:update', 'html', handleUpdate)

    setupFeaturedPostSlider()
  },
}
