import scrollTo from '@common/scroll-to'
import ajax from '@common/ajax'
import { on } from 'delegated-events'

const selectors = {
  paginatedListControls: '[data-paginated-list-controls]',
  paginatedListTrigger: '[data-paginated-list-trigger]',
  paginatedListHide: 'data-paginated-list-hide',
  paginatedList: '[data-paginated-list]',
}

const handleTrigger = async (e) => {
  const target = e.target.closest('a')
  const controls = target.closest(selectors.paginatedListControls)
  const list = document.querySelector(selectors.paginatedList)

  e.preventDefault()

  controls.classList.add('pointer-events-none', 'opacity-60')

  const res = await ajax.get(target.href).json()

  const updatedControls = document.createRange().createContextualFragment(res.pagination)
  controls.replaceWith(updatedControls)

  const updatedList = document.createRange().createContextualFragment(res.list)

  if (target.hasAttribute(selectors.paginatedListHide)) {
    list.replaceChildren(updatedList)

    scrollTo(document.body)
  } else {
    list.append(updatedList)
  }
}

export default {
  can: () => true,
  run: () => {
    on('click', selectors.paginatedListTrigger, handleTrigger)
  },
}
