const mobileBar = document.querySelector('.mobile-bar')

export default {
  can: () => mobileBar,
  run: () => {
    const observer = new IntersectionObserver(([e]) => mobileBar.classList.toggle('is-hidden', !e.isIntersecting))

    observer.observe(document.querySelector('.scroll-pixel'))
  },
}
