export default {
  can: () => document.body.classList.contains('pagetype-splashpage'),
  run: () => {
    const toTop = document.querySelector('.to-top')

    const observer = new IntersectionObserver(function(entries) {
      if (!entries[0].isIntersecting) {
        toTop.classList.add('is-active')
      }
      else {
        toTop.classList.remove('is-active')
      }
    });

    observer.observe(document.querySelector('.site-logo'))
  }
}
