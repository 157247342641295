import { on, fire } from 'delegated-events'
import easydropdown from 'easydropdown-a11y'
import scrollTo from '@common/scroll-to'

const ignorePageTypes = []

const isIos =
  /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

const fn = {
  init: () => {
    on('change', '.js-navigation-dropdown select', fn.handleNavigationSelect)
    on('change', '.js-submit-dropdown select', fn.handleSubmitSelect)
    fn.setup()

    if (window.matchMedia('(min-width: 768px) and (max-width: 1024px)').matches && isIos) {
      document.documentElement.classList.add('is-dropdown-ios-tablet')
    }
  },

  setup: () => {
    document.querySelectorAll('.js-dropdown').forEach((select) => {
      if (!select.querySelector('[selected]')) {
        select.selectedIndex = -1
      }

      fn.setupDropdown(select)
    })
  },

  handleNavigationSelect: (e) => {
    window.location.href = e.target.value
  },

  handleSubmitSelect: (e) => {
    e.target.closest('form').submit()
  },

  setupDropdown: (el) => {
    if (el.tagName !== 'SELECT') {
      return
    }

    const placeholder = el.querySelector('option[value=""]')

    if (placeholder && !placeholder.hasAttribute('data-placeholder')) {
      placeholder.setAttribute('data-placeholder', true)
    }

    const opts = {
      behavior: {
        openOnFocus: true,
        maxVisibleItems: 8,
        liveUpdates: true,
        useNativeUiOnMobile: false,
      },
    }

    easydropdown(el, opts)
  },
}

export const StyledDropdown = {
  init: (select) => fn.setupDropdown(select),
  setup: () => fn.setup(),
}

export default {
  can: () => true,
  run: fn.init,
}
