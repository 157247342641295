import { on } from 'delegated-events'
import easydropdown from 'easydropdown-a11y'
import { slideToggle } from '@common/slide'
import scrollTo from '@common/scroll-to'

const elements = document.querySelectorAll('.element-faqs')

const handleSlideToggle = async (e) => {
  if (e.type === 'keyup' && e.keyCode !== 13) {
    return
  }

  const trigger = e.target.closest('.faq-item')

  if (!trigger) {
    return
  }

  const target = document.getElementById(trigger.getAttribute('aria-controls'))

  if (!target) {
    return
  }

  if (window.matchMedia('(min-width: 1024px)').matches) {
    const group = trigger.closest('.faq-group')
    const holder = group.querySelector('.faq-answer-holder')

    group.querySelectorAll('.faq-item[aria-expanded="true"]').forEach((x) => {
      x.setAttribute('aria-expanded', false)
      x.parentNode.classList.remove('is-active')
    })

    if (trigger.getAttribute('aria-expanded') === 'false') {
      trigger.setAttribute('aria-expanded', 'true')
      holder.innerHTML = `<div class="faq-answer-inner">${target.innerHTML}</div>`
      trigger.parentNode.classList.add('is-active')

      const offset = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--site-header-height'))

      const shouldScrollTo = holder.querySelector('.faq-answer-inner').getBoundingClientRect().top < offset

      if (shouldScrollTo) {
        scrollTo(holder.querySelector('.faq-answer-inner'), 48)
      }
    }

    return
  }

  if (trigger.getAttribute('aria-expanded') === 'false') {
    trigger.setAttribute('aria-expanded', 'true')

    await slideToggle(target)

    trigger.parentNode.classList.add('is-active')
  } else {
    slideToggle(target)
    trigger.setAttribute('aria-expanded', 'false')
    trigger.parentNode.classList.remove('is-active')
  }
}

const closeOtherTabs = (activeTrigger) => {
  const container = activeTrigger.closest('[data-faq-tabs]')
  const otherTriggers = Array.from(container.querySelectorAll('[data-tab-trigger][aria-selected="true"]')).filter(
    (x) => x !== activeTrigger
  )

  otherTriggers.forEach((x) => closeTab(x))
}

const openTab = async (trigger) => {
  const panel = document.getElementById(trigger.getAttribute('aria-controls'))

  trigger.setAttribute('aria-selected', 'true')
  panel.setAttribute('aria-hidden', 'false')
}

const closeTab = (trigger) => {
  const panel = document.getElementById(trigger.getAttribute('aria-controls'))

  trigger.setAttribute('aria-selected', 'false')
  panel.setAttribute('aria-hidden', 'true')
}

const handleTab = (e) => {
  e.preventDefault()

  const trigger = e.target.closest('[data-tab-trigger]')

  if (trigger.getAttribute('aria-selected') === 'false') {
    openTab(trigger)
    closeOtherTabs(trigger)
  }
}

export default {
  can: () => elements.length,
  run: () => {
    on('click', '.faq-item', handleSlideToggle)
    on('keyup', '.faq-item', handleSlideToggle)
    on('click', '[data-tab-trigger]', handleTab)

    elements.forEach((element) => {
      const dropdown = element.querySelector('.faq-dropdown')

      if (dropdown) {
        easydropdown(dropdown.querySelector('select'), {
          behavior: {
            openOnFocus: true,
            maxVisibleItems: 8,
            liveUpdates: true,
            useNativeUiOnMobile: false,
          },
          callbacks: {
            onSelect: (value) => {
              const tab = element.querySelector(`[data-tab-trigger][id="${value}"]`)

              if (tab) {
                handleTab({ preventDefault: () => { }, target: tab })
              }
            },
          },
        })
      }
    })

    if (window.matchMedia('(min-width: 1024px)').matches) {
      document.querySelectorAll('.faq-group').forEach((group) => {
        const holder = group.querySelector('.faq-answer-holder')
        const firstItem = group.querySelector('.faq-item.is-first')

        if (firstItem) {
          const target = document.getElementById(firstItem.getAttribute('aria-controls'))
          firstItem.setAttribute('aria-expanded', 'true')
          holder.innerHTML = `<div class="faq-answer-inner">${target.innerHTML}</div>`
          firstItem.parentNode.classList.add('is-active')
        }
      })
    }
  },
}
