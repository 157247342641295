import { on } from 'delegated-events'
import throttle from 'lodash/throttle'
import ajax from '@common/ajax'
import easydropdown from 'easydropdown-a11y'
import { initSliders } from '@elements/reviews'
import { StyledDropdown } from '@components/dropdown'
import Swiper, { Navigation, Thumbs } from 'swiper'

Swiper.use([Navigation, Thumbs])

const holder = document.querySelector('.field-page-content')
const fieldNav = document.querySelector('.field-rentals-nav')
const fieldSelectors = document.querySelectorAll('.field-selector')

const fieldPageCache = {}
let sidebarObserver = null

const init = () => {
  if (fieldSelectors.length) {
    fieldSelectors.forEach((el) => {
      easydropdown(el, {
        behavior: {
          openOnFocus: true,
          maxVisibleItems: 15,
          liveUpdates: true,
          useNativeUiOnMobile: false,
        },
        callbacks: {
          onSelect: (value) => {
            if (value) {
              handleFieldChange(value)
            }
          },
        },
      })
    })
  }

  on('click', '.field-rentals-nav button', handleFieldChange)

  handleSidebar()

  setupMediaGallery()

  window.addEventListener('resize', throttle(handleSidebar, 250))
}

const handleSidebar = () => {
  const siteHeader = document.querySelector('.site-header')
  const siteHeaderHeight = siteHeader.offsetHeight
  const sidebar = document.querySelector('.field-rentals-form-sidebar')
  const sidebarHeight = sidebar.offsetHeight + 200 // Account for last dropdown height
  const sidebarIndicator = document.querySelector('.field-rentals-form-sidebar-indicator')
  const visibleHeight = window.innerHeight - siteHeaderHeight
  const canStick = window.matchMedia('(min-width: 1024px)').matches && sidebarHeight <= visibleHeight

  sidebar.classList.toggle('sticky', canStick)

  if (sidebarObserver) {
    sidebarObserver.unobserve(sidebar)
    sidebarObserver = null
    sidebarIndicator.classList.remove('is-active')
  }

  if (!canStick && window.matchMedia('(min-width: 1024px)').matches) {
    const topOffset = sidebar.offsetHeight - siteHeaderHeight + 16

    sidebarObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          sidebarIndicator.classList.remove('is-active')
        } else {
          sidebarIndicator.classList.add('is-active')
        }
      },
      {
        rootMargin: `${topOffset}px 0px 0px 0px`,
        threshold: [1],
      }
    )

    sidebarObserver.observe(sidebar)
  }
}

const setupMediaGallery = () => {
  const gallery = holder.querySelector('.field-media-gallery')

  if (!gallery) {
    return
  }

  const mainContainer = gallery.querySelector('.media-gallery-main .swiper')
  const thumbContainer = gallery.querySelector('.media-gallery-thumbs .swiper')
  const numSlides = mainContainer.querySelectorAll('.swiper-slide').length

  if (numSlides > 1) {
    const thumbSwiper = new Swiper(thumbContainer, {
      spaceBetween: 8,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
      allowTouchMove: false,
      touchRatio: 20,
      breakpoints: {
        1024: {
          slidesPerView: 6,
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 16,
        },
      },
    })

    new Swiper(mainContainer, {
      loop: true,
      spaceBetween: 8,
      loopedSlides: numSlides,
      navigation: {
        nextEl: gallery.querySelector('.slider-control-next'),
        prevEl: gallery.querySelector('.slider-control-prev'),
      },
      thumbs: {
        swiper: thumbSwiper,
      },
    })
  }
}

const reInit = () => {
  initSliders()
  setupMediaGallery()
  handleSidebar()

  holder.querySelectorAll('.js-dropdown').forEach((el) => StyledDropdown.init(el))
}

const handleFieldChange = async (e) => {
  let field

  if (fieldNav) {
    fieldNav.querySelectorAll('button.is-active').forEach((x) => x.classList.remove('is-active'))
  }

  if (typeof e === 'object') {
    const button = e.target.closest('button')
    field = button.dataset.field
    button.classList.add('is-active')

    if (fieldSelectors.length) {
      fieldSelectors.forEach((el) => {
        el.value = ''
      })
    }
  } else {
    field = e
  }

  if (!field) {
    return
  }

  holder.classList.add('is-loading')

  const endpoint = `${window.location.origin}${holder.dataset.endpoint}`
  const endpointUrl = new URL(endpoint)

  let html = ''

  if (field in fieldPageCache) {
    html = fieldPageCache[field]
  } else {
    const url = new URL(endpoint)

    const params = new URLSearchParams(url.search)
    params.set('fetchField', field)

    url.search = params.toString()

    const res = await ajax.get(url).text()

    fieldPageCache[field] = res
    html = res
  }

  holder.innerHTML = html

  reInit()

  holder.classList.remove('is-loading')

  const replaceUrl = `${endpointUrl.pathname}${field}${endpointUrl.search}`

  window.history.replaceState({}, '', replaceUrl)
}

export default {
  can: () => document.body.classList.contains('pagetype-fieldspage'),
  run: init,
}
