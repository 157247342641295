import easydropdown from 'easydropdown-a11y'
import ajax from '@common/ajax'
import { initSliders } from '@elements/reviews'

const holder = document.querySelector('.league-page-content')

const init = () => {
  const sessionSwitcher = document.querySelector('.session-switcher')

  if (sessionSwitcher) {
    easydropdown(sessionSwitcher, {
      behavior: {
        openOnFocus: true,
        maxVisibleItems: 15,
        liveUpdates: true,
        useNativeUiOnMobile: false,
      },
      callbacks: {
        onSelect: (value) => handleSessionChange(value),
      },
    })
  }
}

const handleSessionChange = async (session) => {
  holder.classList.add('is-loading')
  const endpoint = `${window.location.origin}${holder.dataset.endpoint}`
  const endpointUrl = new URL(endpoint)

  const fetchUrl = new URL(endpoint)

  const params = new URLSearchParams(fetchUrl.search)
  params.set('fetchSession', session)

  fetchUrl.search = params.toString()

  const res = await ajax.get(fetchUrl).text()

  holder.innerHTML = res

  init()
  initSliders()

  holder.classList.remove('is-loading')

  const replaceUrl = `${endpointUrl.pathname}${session}${endpointUrl.search}`

  window.history.replaceState({}, '', replaceUrl)
}

export default {
  can: () => document.body.classList.contains('pagetype-leaguepage'),
  run: init,
}
