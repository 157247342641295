import { on } from 'delegated-events'

const siteHeader = document.querySelector('.site-header')
const siteMenu = document.querySelector('.site-menu')

const openMenu = () => {
  document.documentElement.classList.add('js-site-menu-open')
}

const closeMenu = () => {
  document.documentElement.classList.remove('js-site-menu-open')

  siteMenu.querySelectorAll('.is-active').forEach((el) => {
    const trigger = el.querySelector('[data-slide-toggle]')
    const target = document.getElementById(trigger.getAttribute('aria-controls'))

    target.style = null
    trigger.setAttribute('aria-expanded', 'false')
    el.classList.remove('is-active')
  })
}

export default {
  can: () => siteHeader,
  run: () => {
    const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('is-stuck', e.intersectionRatio < 1), {
      threshold: [1],
    })

    observer.observe(siteHeader)

    on('click', '.site-menu-open', openMenu)
    on('click', '.site-menu-close', closeMenu)
    on('click', '.site-menu-screen', closeMenu)
  },
}
