import ScrollReveal from 'scrollreveal'

const fn = {
  init: () => {
    fn.setupScrollReveals()
  },

  setupScrollReveals: () => {
    ScrollReveal().reveal('[data-sr-fade-up]', {
      distance: '125%',
      origin: 'bottom',
      interval: 50,
    })

    ScrollReveal().reveal('[data-sr-zoom]', {
      scale: 0.4,
      origin: 'center',
      interval: 50,
    })

    ScrollReveal().reveal('[data-sr-touch]', {
      opacity: 1,
      afterReveal: (el) => el.classList.add('sr-touched'),
    })

    ScrollReveal().reveal('[data-sr-fade-left]', {
      distance: '125%',
      origin: 'right',
      interval: 50,
    })

    ScrollReveal().reveal('[data-sr-slide-left]', {
      distance: '125%',
      origin: 'right',
      opacity: 1,
      interval: 50,
    })

    ScrollReveal().reveal('[data-sr-fade-right]', {
      distance: '125%',
      origin: 'left',
      interval: 50,
    })
  },
}

export default {
  can: () => true,
  run: fn.init,
}
