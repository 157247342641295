export const trackGoogleAnalytics = (event) => {
  if (window.dataLayer) {
    dataLayer.push(event)
  }
}

export const createUrl = (base, formData = {}, append = false) => {
  let data = formData

  if (formData instanceof FormData === false) {
    data = new FormData()

    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value)
    }
  }

  if (typeof base === 'object') {
    const baseUrl = new URL(base)

    if (append) {
      const params = baseUrl.searchParams

      for (const [key, value] of data) {
        params.set(key, value)
      }

      return baseUrl
    } else {
      const params = new URLSearchParams(data)

      return new URL(`${base}?${params}`)
    }
  }

  if (typeof base === 'string') {
    const params = new URLSearchParams(formData)
    const url = new URL(`${base}?${params}`)

    return url
  }
}
