import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

export default {
  can: () => document.body.classList.contains('pagetype-blogpostpage'),
  run: () => {
    const slider = document.querySelector('.related-posts-slider')

    if (!slider) {
      return
    }

    const container = slider.querySelector('.swiper')
    const numSlides = slider.querySelectorAll('.swiper-slide').length
    const controls = slider.querySelector('.slider-controls')

    const handleControls = (swiper) => {
      if (controls) {
        if (swiper.isBeginning && swiper.isEnd) {
          controls.classList.add('force-hidden')
        } else {
          controls.classList.remove('force-hidden')
        }
      }
    }

    if (numSlides > 1) {
      new Swiper(container, {
        slidesPerView: 'auto',
        slidesPerGroupAuto: true,
        allowTouchMove: false,
        simulateTouch: false,
        pagination: {
          el: slider.querySelector('.slider-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: slider.querySelector('.slider-control-next'),
          prevEl: slider.querySelector('.slider-control-prev'),
        },
        on: {
          resize: handleControls,
          afterInit: handleControls,
        },
      })
    }
  },
}
