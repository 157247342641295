import { on } from 'delegated-events'

const topBar = document.querySelector('.site-announcement-bar')

const handleClose = () => {
  topBar.remove()
  setHeight()
}

const setHeight = () => {
  document.documentElement.style.setProperty('--site-announcement-height', `${topBar.offsetHeight}px`)
}

export default {
  can: () => topBar,
  run: () => {
    on('click', '.site-announcement-bar-close', handleClose)

    setHeight()
    window.addEventListener('resize', setHeight)
  },
}
