import { CountUp } from 'countup.js'

const counters = document.querySelectorAll('[data-counter]')

export default {
  can: () => counters.length,
  run: () => {
    counters.forEach((el) => {
      const useGrouping = el.dataset.useGrouping
      const decimalPlaces = el.dataset.decimalPlaces
      const end = el.dataset.end

      const opts = {
        useGrouping: !!+useGrouping,
      }

      if (decimalPlaces) {
        opts.decimalPlaces = decimalPlaces
      }

      const counter = new CountUp(el, end, opts)

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            counter.start()
          }
        })
      }, {})

      observer.observe(el)
    })
  },
}
