import { on } from 'delegated-events'
import { slideToggle } from '@common/slide'
import scrollTo from '@common/scroll-to'

const handleToggle = async (e) => {
  if (e.type === 'keyup' && e.keyCode !== 13) {
    return
  }

  const trigger = e.target.closest('[data-slide-toggle]')

  if (!trigger) {
    return
  }

  const target = document.getElementById(trigger.getAttribute('aria-controls'))

  if (!target) {
    return
  }

  const otherTriggers = Array.from(
    document.querySelectorAll(`[data-slide-toggle][aria-controls="${trigger.getAttribute('aria-controls')}"]`)
  ).filter((x) => x !== trigger)

  if (trigger.getAttribute('aria-expanded') === 'false') {
    trigger.setAttribute('aria-expanded', 'true')
    otherTriggers.forEach((x) => x.setAttribute('aria-expanded', 'true'))

    await slideToggle(target)

    if (trigger.dataset.slideToggle === 'scroll') {
      scrollTo(target)
    }

    const holder = trigger.closest('[data-slide-toggle-holder]')

    if (holder) {
      holder.classList.add('is-active')
    } else {
      trigger.parentNode.classList.add('is-active')
      otherTriggers.forEach((x) => x.parentNode.classList.add('is-active'))
    }
  } else {
    slideToggle(target)
    trigger.setAttribute('aria-expanded', 'false')
    otherTriggers.forEach((x) => x.setAttribute('aria-expanded', 'false'))

    const holder = trigger.closest('[data-slide-toggle-holder]')

    if (holder) {
      holder.classList.remove('is-active')
    } else {
      trigger.parentNode.classList.remove('is-active')
      otherTriggers.forEach((x) => x.parentNode.classList.remove('is-active'))
    }
  }
}

const handleOpenedDefault = () => {
  document.querySelectorAll('[data-slide-toggle-opened-default]').forEach((el) => {
    const trigger = el.closest('[data-slide-toggle]')
    const target = document.getElementById(trigger.getAttribute('aria-controls'))

    if (trigger && target) {
      trigger.setAttribute('aria-expanded', 'true')
      target.classList.remove('hidden')
    }
  })
}

export default {
  can: () => true,
  run: () => {
    const clickArgs = ['click', '[data-slide-toggle]', handleToggle]
    const keyArgs = ['keyup', '[data-slide-toggle]', handleToggle]

    on(...clickArgs)
    on(...keyArgs)

    handleOpenedDefault()
  },
}
