import { up, down, toggle } from 'slide-element'

export const slideUp = (element, duration = 400, display = 'block') => {
  element.classList.add('is-sliding')

  const slide = up(element, {
    duration,
    display,
    easing: 'ease-out',
  })

  slide.then(() => {
    element.classList.remove('is-sliding')
  })

  return slide
}

export const slideDown = (element, duration = 400, display = 'block') => {
  element.classList.add('is-sliding')

  const slide = down(element, {
    duration,
    display,
    easing: 'ease-out',
  })

  slide.then(() => {
    element.classList.remove('is-sliding')
  })

  return slide
}

export const slideToggle = (element, duration = 400, display = 'block') => {
  element.classList.add('is-sliding')

  const slide = toggle(element, {
    duration,
    display,
    easing: 'ease-out',
  })

  slide.then(() => {
    element.classList.remove('is-sliding')
  })

  return slide
}
