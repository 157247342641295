const setupMap = () => {
  const mapEl = document.getElementById('contact-map')

  if (!mapEl) {
    return
  }

  const data = JSON.parse(mapEl.dataset.json)

  const mapOptions = {
    center: new google.maps.LatLng(data.lat, data.lng),
    zoom: 16,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    scrollwheel: false,
    gestureHandling: 'none',
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  }

  const map = new google.maps.Map(mapEl, mapOptions)
  const bounds = new google.maps.LatLngBounds()

  google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
    const mapMarker = {
      url: "data:image/svg+xml,%3Csvg viewBox='0 0 64 106' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill-rule='nonzero' fill='none'%3E%3Cpath d='M0 31.7c0 16.675 28.615 70.566 29.84 72.848a2.114 2.114 0 0 0 3.72 0C34.786 102.266 63.4 48.375 63.4 31.7a31.7 31.7 0 0 0-63.4 0Z' fill='%23007AB8'/%3E%3Cpath fill='%23FFF' d='M21.16 33.08 33 25 21.754 56H13zM39.961 19.931 51 12 35.695 56H27z'/%3E%3C/g%3E%3C/svg%3E",
      scaledSize: new google.maps.Size(48, 80),
    }
    const marker = new google.maps.Marker({
      map: map,
      title: data.title,
      animation: google.maps.Animation.DROP,
      position: new google.maps.LatLng(data.lat, data.lng),
      panposition: new google.maps.LatLng(data.lat, data.lng),
      icon: mapMarker,
    })

    google.maps.event.addListener(marker, 'click', () => {
      window.open(data.url, '_blank')
    })

    google.maps.event.addListener(marker, 'mouseover', function() {
      this.setAnimation(google.maps.Animation.BOUNCE)
    })

    google.maps.event.addListener(marker, 'mouseout', function() {
      this.setAnimation(null)
    })

    bounds.extend(marker.getPosition())
  })
}

export default {
  can: () => document.body.classList.contains('pagetype-contactpage'),
  run: setupMap,
}
