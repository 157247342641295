import Rellax from 'rellax'

const images = document.querySelectorAll('.element-largeimagecallout .element-image')

const fn = {
  init: () => {
    window.addEventListener('load', () => {
      new Rellax('.element-image', {
        wrapper: null,
        center: true,
        round: true,
        vertical: true,
        horizontal: false,
        speed: -5,
      })
    })
  },
}

export default {
  can: () => images.length,
  run: fn.init,
}
