import { on } from 'delegated-events'

export default {
  can: () => document.body.classList.contains('pagetype-plananeventpage'),
  run: () => {
    const rentalTypeField = document.querySelector('.plan-an-event-form select[name="RentalType"]')

    if (rentalTypeField) {
      on('click', '.rental-type-btn', (e) => {
        const type = e.target.closest('button').dataset.type

        rentalTypeField.value = type
      })
    }
  },
}
