import Swiper, { Navigation, Thumbs } from 'swiper'

Swiper.use([Navigation, Thumbs])

const galleries = document.querySelectorAll('.media-gallery')

export default {
  can: () => galleries.length,
  run: () => {
    galleries.forEach((gallery) => {
      const mainContainer = gallery.querySelector('.media-gallery-main .swiper')
      const thumbContainer = gallery.querySelector('.media-gallery-thumbs .swiper')
      const numSlides = mainContainer.querySelectorAll('.swiper-slide').length

      if (numSlides > 1) {
        const thumbSwiper = new Swiper(thumbContainer, {
          spaceBetween: 8,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesProgress: true,
          allowTouchMove: false,
          touchRatio: 20,
          breakpoints: {
            1280: {
              spaceBetween: 24,
            },
          },
        })

        new Swiper(mainContainer, {
          slidesPerView: 'auto',
          loop: true,
          loopedSlides: numSlides,
          navigation: {
            nextEl: gallery.querySelector('.slider-control-next'),
            prevEl: gallery.querySelector('.slider-control-prev'),
          },
          thumbs: {
            swiper: thumbSwiper,
          },
        })
      }
    })
  },
}
