import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

const elements = document.querySelectorAll('.element-blogposts')

export default {
  can: () => elements.length,
  run: () => {
    elements.forEach((el) => {
      const slider = el.querySelector('.blog-posts-slider')
      const container = slider.querySelector('.swiper')
      const numSlides = slider.querySelectorAll('.swiper-slide').length
      const controls = slider.querySelector('.slider-controls')

      const handleControls = (swiper) => {
        if (controls) {
          if (swiper.isBeginning && swiper.isEnd) {
            controls.classList.add('force-hidden')
          } else {
            controls.classList.remove('force-hidden')
          }
        }
      }

      if (numSlides > 1) {
        new Swiper(container, {
          slidesPerView: 'auto',
          slidesPerGroupAuto: true,
          allowTouchMove: false,
          simulateTouch: false,
          pagination: {
            el: slider.querySelector('.slider-pagination'),
            clickable: true,
          },
          navigation: {
            nextEl: slider.querySelector('.slider-control-next'),
            prevEl: slider.querySelector('.slider-control-prev'),
          },
          on: {
            resize: handleControls,
            afterInit: handleControls,
          },
        })
      }
    })
  },
}
