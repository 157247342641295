import { on } from 'delegated-events'
import { slideUp, slideDown } from '@common/slide'

const sidebar = document.querySelector('.sports-menu-sidebar')

const openMenu = async (trigger) => {
  const target = document.getElementById(trigger.getAttribute('aria-controls'))
  target.classList.add('is-active')
  trigger.setAttribute('aria-expanded', 'true')

  if (window.matchMedia('(min-width: 768px)').matches) {
    slideDown(target)
  }
}

const closeMenu = async (trigger) => {
  const target = document.getElementById(trigger.getAttribute('aria-controls'))
  target.classList.remove('is-active')
  trigger.setAttribute('aria-expanded', 'false')

  if (window.matchMedia('(min-width: 768px)').matches) {
    slideUp(target)
  }
}

const handleTrigger = (e) => {
  const trigger = e.target.closest('.sports-menu-trigger')

  if (trigger.getAttribute('aria-expanded') === 'false') {
    openMenu(trigger)
  } else if (trigger.getAttribute('aria-expanded') === 'true') {
    closeMenu(trigger)
  }
}

export default {
  can: () => sidebar,
  run: () => {
    const triggers = sidebar.querySelectorAll('.sports-menu-trigger')

    on('click', '.sports-menu-trigger', handleTrigger)

    if (window.matchMedia('(min-width: 768px)').matches) {
      triggers.forEach((trigger) => {
        const target = document.getElementById(trigger.getAttribute('aria-controls'))
        target.classList.add('is-active')
        trigger.setAttribute('aria-expanded', 'true')
      })
    }

    window.addEventListener('click', (e) => {
      if (window.matchMedia('(max-width: 767px)').matches) {
        if (!e.target.closest('.sports-menu') && !e.target.closest('.sports-menu-trigger')) {
          triggers.forEach((trigger) => {
            closeMenu(trigger)
          })
        }
      }
    })
  },
}
